import React, { useContext, useEffect, useState } from 'react';
import { Typography, Tag, Button, TextInput, Checkbox } from '@zydalabs/zac-react';
import { Form, Formik } from 'formik';
import { path } from 'ramda';
import { ChevronsRightIcon, XIcon, LogosWhatsAppIcon, ChartColumnIcon } from '@zydalabs/zac-icons-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMutation } from '@apollo/client';

import 'style.css';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';

import { Text, Field } from 'components/service';
import riderValidationSchema from 'components/common/verd/RiderForm/validationSchema';
import { PhoneInput } from 'components/form/elements';
import { RIDER_JOB_TYPES } from 'constants/riderJobTypes';
import VERD_RIDER_CREATED_THROUGH from 'constants/verdRiderCreatedThrough';
import { RIDER_LANGUAGES } from 'constants/riderLanguages';
import * as translations from 'constants/translations';
import { useCreateStoreDriver } from 'service/hooks';
import { useSelectedStore } from 'hooks/index';
import { ORDER_STATUS } from 'constants/order';
import * as schemas from './schemas';
import VerdVideo from './VerdVideo';

const AddRiderModal = ({
  close,
  closeable,
  mutateFetchVerdDrivers,
  setShouldShowRiderModal,
  sendChangeStatus,
  handleAssignDriverToOrder,
}) => {
  const notifications = useContext(notificationsContext);
  const { branches, selectedStore } = useContext(userContext);
  const { translate, direction } = useContext(localeContext);

  const [dontShowAgain, setDontShowAgain] = useState();
  const hasDontShowAgainButton = !!setShouldShowRiderModal;

  const verd = selectedStore?.restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const selectedStoreId = useSelectedStore();
  const [isAddRiderLoading, setIsAddingRiderLoading] = useState(false);

  const branchesIdList = branches.map(branch => branch.id);
  const createStoreDriver = useCreateStoreDriver();

  const [updateCourierSetting] = useMutation(schemas.UPDATE_COURIER_SETTINGS);

  const handleRiderCreation = async data => {
    const variables = {
      name: data.name,
      phone: data.phone.includes('+') ? data.phone : `+${data.phone}`,
      jobType: data.jobType,
      language: data.language,
      branches: data.branches,
      createdThrough: VERD_RIDER_CREATED_THROUGH.ORDER_DETAILS_POPUP,
      storeName: selectedStore.titleEn,
    };
    const response = await createStoreDriver({ ...variables, storeId: selectedStoreId });
    if (response?.errors?.length) {
      notifications.show(<Text value={[response?.errors?.[0]?.message]} />, 'error');
    } else {
      await mutateFetchVerdDrivers();
      await handleAssignDriverToOrder(response?.createStoreDriver);
      notifications.show(<Text value={translations.ADD_RIDER_SUCCESS} />);
      if (hasDontShowAgainButton) sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
      setIsAddingRiderLoading(false);
      close();
      return response;
    }
    return null;
  };

  const closeModal = () => {
    close();
    closeable(true);
    if (hasDontShowAgainButton) {
      sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
      setShouldShowRiderModal(!dontShowAgain);
    }
  };

  useEffect(() => {
    if (hasDontShowAgainButton) closeable(false);
  }, []);

  const onSubmit = data => {
    setIsAddingRiderLoading(true);
    if (!data.name.trim()) {
      return notifications.show(<Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />, 'error');
    }
    try {
      handleRiderCreation(data);
    } catch (err) {
      const errors = path(['graphQLErrors', 0, 'extensions', 'exception', 'body'], err);

      if (errors?.phone_number) {
        notifications.show(<Text value={translations.PHONE_IS_INVALID} />, 'error');
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col" style={{ direction }}>
      <div className="relative">
        <VerdVideo />
        <div className="absolute top-4 right-4 cursor-pointer">
          <Button
            rounded
            size="small"
            variant="secondary"
            endIcon={<XIcon width="32px" color="#c1c1c1" onClick={closeModal} />}
          />
        </div>
      </div>
      <div className="mx-6 flex gap-4 flex-col mt-4">
        <div className="flex">
          <Typography variant="heading24">
            <Text value={translations.DELIVER_BY_VERD_DISPATCH} />
          </Typography>
          <div className="mx-2 mt-1">
            <Tag
              color="warning"
              inner={{
                text: translate(translations.NEW),
              }}
              size="medium"
            />
          </div>
        </div>
        <Typography variant="body16">
          <Text value={translations.CONTROL_FLEET} />
        </Typography>
        <div>
          <div className="flex gap-2.5 items-start mb-2">
            <LogosWhatsAppIcon width="20px" color="black" />
            <Typography variant="element14">
              <Text value={translations.SEND_ORDER_THROUGH_WHATSAPP} />
            </Typography>
          </div>
          <div className="flex gap-2.5 items-start mb-5">
            <ChartColumnIcon width="20px" color="black" />
            <Typography variant="element14">
              <Text value={translations.REALTIME_UPDATES} />
            </Typography>
          </div>
        </div>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            jobType: RIDER_JOB_TYPES.FULL_TIME,
            language: RIDER_LANGUAGES.ENGLISH,
            branches: branchesIdList,
          }}
          validationSchema={riderValidationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="flex gap-2 items-baseline">
              <div className="text-input">
                <Field
                  type="text"
                  name="name"
                  component={TextInput}
                  placeholder={translate(translations.RIDER_NAME)}
                  size="small"
                />
              </div>
              <div className="text-input">
                <Field
                  type="text"
                  name="phone"
                  component={PhoneInput}
                  defaultCountry={selectedStore.countryCode?.toLowerCase()}
                  size="large"
                />
              </div>
              <div className="h-min">
                <Button
                  rounded
                  size="medium"
                  text={translate(translations.ADD_RIDER)}
                  type="submit"
                  isLoading={isAddRiderLoading}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      <div className="self-center flex gap-2 cursor-pointer mt-10 items-center">
        <Typography variant="body16" color="#1F1F1F" cursor="pointer">
          <Text
            value={translations.SKIP_FOR_NOW}
            onClick={() => {
              close();
              closeable(true);
              sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
              if (hasDontShowAgainButton) setShouldShowRiderModal(false);
            }}
          />
        </Typography>
        <div className={cx(direction === 'rtl' ? 'rotate-180' : '')}>
          <ChevronsRightIcon width="20px" color="black" />
        </div>
      </div>
      {hasDontShowAgainButton && (
        <div className="mx-6 zac-checkbox mt-7">
          <Checkbox
            label="Don't show again"
            onChange={e => {
              updateCourierSetting({
                variables: {
                  restaurantId: selectedStoreId,
                  courierId: verd.courierId,
                  showRiderModal: !e.checked,
                },
              });
              setDontShowAgain(e.checked);
            }}
          />
        </div>
      )}
    </div>
  );
};

AddRiderModal.propTypes = {
  close: PropTypes.func,
  mutateFetchVerdDrivers: PropTypes.func,
  closeable: PropTypes.func,
  setShouldShowRiderModal: PropTypes.func,
  sendChangeStatus: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
};

export default AddRiderModal;
