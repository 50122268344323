import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Button, Typography, useTheme } from '@zydalabs/zac-react';
import { SettingsIcon } from '@zydalabs/zac-icons-react';
import { navigate } from '@reach/router';

import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { replaceParams } from 'utils/index';
import { useSelectedStore } from 'hooks';
import SearchInput from 'common/components/SearchInput';
import { ORDER_STATUS } from 'constants/order';
import StoreRider from './StoreRider';
import StoreCourier from './StoreCourier';

const AssignDeliveryModal = ({
  close,
  handleAssignDriverToOrder,
  handleAssignCourierToOrder,
  openOrderDeliveryConfirmationPopUp,
  isCourierNotDeclinedTheRequest,
  assigned,
  restaurantCouriersWithLogos,
  riders,
  quickAssignCourierId,
  sendChangeStatus,
  closeable,
}) => {
  const { translate } = useContext(localeContext);
  const {
    settings,
    selectedStore: { restaurantCourierSetting },
  } = useContext(userContext);
  const { colors } = useTheme();
  const storeId = useSelectedStore();
  const [searchInput, setSearchInput] = useState('');
  const [selected, setSelected] = useState(assigned);

  const shouldChangeStatus = !!sendChangeStatus;
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isVerdRiderSelected = !!selected?.phone;
  const isVerdEnabled = settings?.enableVerd;

  useEffect(() => {
    closeable(false);
  }, []);

  const closeModal = () => {
    close();
    closeable(true);
    if (shouldChangeStatus) sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
  };

  const routeToVerdPage = () => {
    if (shouldChangeStatus) sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });

    navigate(
      replaceParams(paths.verd, {
        storeId,
      }),
    );
  };

  const filteredCouriersAndRiders = useMemo(
    () =>
      restaurantCouriersWithLogos?.concat(riders)?.filter(courierOrRider => {
        if (searchInput === '') {
          return courierOrRider;
        }
        return courierOrRider.phone
          ? courierOrRider.name?.toLowerCase()?.includes(searchInput)
          : courierOrRider.courierDetails?.name?.toLowerCase()?.includes(searchInput);
      }),
    [searchInput, selected, setSelected],
  );

  const ridersList = filteredCouriersAndRiders
    ?.filter(rider => !!rider.phone)
    ?.map(rider => (
      <StoreRider key={rider.id} rider={rider} verd={verd} selected={selected} setSelected={setSelected} />
    ));

  const couriersList = filteredCouriersAndRiders
    ?.filter(rider => !rider.phone)
    ?.map(courier => (
      <StoreCourier
        key={Number(courier.courierId)}
        courier={courier}
        selected={selected}
        setSelected={setSelected}
        quickAssignCourierId={quickAssignCourierId}
      />
    ));

  const assignDelivery = () =>
    isVerdRiderSelected ? handleAssignDriverToOrder(selected) : handleAssignCourierToOrder(selected);

  const handleAssignButton = () =>
    isCourierNotDeclinedTheRequest
      ? openOrderDeliveryConfirmationPopUp({
          assignDriverToOrder: isVerdRiderSelected ? handleAssignDriverToOrder : handleAssignCourierToOrder,
          assignee: selected,
        })
      : assignDelivery();

  const handleSearch = e => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  return (
    <div className="min-h-full flex flex-col">
      <div className="sticky top-0 bg-white w-full flex justify-between items-center border-b px-6 pt-7 pb-6">
        <Typography variant="heading24">{translate(translations.ASSIGN_DELIVERY)}</Typography>
        <Button
          variant="tertiary"
          text={translate(translations.MANAGE)}
          rounded
          size="small"
          onClick={routeToVerdPage}
          startIcon={<SettingsIcon width="20px" color={colors.blue.primary} />}
        />
      </div>
      <div className="flex flex-col px-6 py-5">
        <SearchInput
          searchValue={searchInput}
          setSearchValue={handleSearch}
          bgColor="bg-gray-100"
          placeholder={translate(translations.SEARCH_PLACEHOLDER)}
        />
        {couriersList.length !== 0 && (
          <>
            <Typography variant="heading14" mt={16} mb={8}>
              {translate(translations.ACTIVE_COURIERS(couriersList.length))}
            </Typography>
            {couriersList}
          </>
        )}
        {isVerdEnabled && ridersList.length !== 0 && (
          <>
            <Typography variant="heading14" mt={16} mb={8}>
              {translate(translations.ACTIVE_RIDERS(ridersList.length))}
            </Typography>
            {ridersList}
          </>
        )}
      </div>
      <div className="sticky bottom-0 bg-white flex justify-between py-4 px-6 border-t gap-4 mt-auto">
        <Button
          variant="secondary"
          text={translate(translations.ASSIGN_LATER)}
          rounded
          isFluid
          size="large"
          onClick={() => {
            closeModal();
          }}
        />
        <Button
          text={translate(translations.ASSIGN_NOW)}
          rounded
          isFluid
          size="large"
          onClick={() => {
            handleAssignButton();
            closeModal();
          }}
        />
      </div>
    </div>
  );
};

export default AssignDeliveryModal;
