import React, { useContext } from 'react';
import cx from 'classnames';
import { useQuery } from '@apollo/client';

import { Field, Text } from 'components/service';
import { Label, Row } from 'components/form/generic';
import { Input, Select } from 'components/form/elements';
import { CITIES } from 'pages/setup/branch/List/schemas';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';

const BranchAddress = ({ handleChange, branchId }) => {
  const { lang, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { data } = useQuery(CITIES, {
    variables: {
      countryId: selectedStore.countryId,
    },
  });
  return (
    <>
      <Row className="flex-col">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
            <Label title={<Text value={translations.ADDRESS_IN_ENGLISH} />}>
              <Field
                type="text"
                name="addressEn"
                placeholder={translations.ENTER_ADDRESS[0]}
                component={Input}
                data-testid="business-location-address-en"
              />
            </Label>
          </div>
          <div className="w-full mb-0 md:mb-0">
            <Label title={<Text value={translations.ADDRESS_IN_ARABIC} />}>
              <Field
                type="text"
                direction="rtl"
                name="addressAr"
                placeholder={translations.ENTER_ADDRESS[1]}
                component={Input}
                data-testid="business-location-address-ar"
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mt-2">
          <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
            <Label title={<Text value={translations.BUILDING_NUMBER} />}>
              <Field
                type="text"
                name="buildingNumber"
                placeholder={
                  lang === 'en' ? translations.ENTER_BUILDING_NUMBER[0] : translations.ENTER_BUILDING_NUMBER[1]
                }
                component={Input}
                data-testid="business-location-building-number"
              />
            </Label>
          </div>
          <div className="w-full mb-0 md:mb-0">
            <Label title={<Text value={translations.STREET_NAME} />}>
              <Field
                type="text"
                name="street"
                placeholder={lang === 'en' ? translations.ENTER_STREET_NAME[0] : translations.ENTER_STREET_NAME[1]}
                component={Input}
                data-testid="business-location-street-name"
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-col mt-2 md:flex-row justify-between items-start">
          <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
            <Label title={<Text value={translations.BLOCK} />}>
              <Field
                type="text"
                name="block"
                placeholder={lang === 'en' ? translations.ENTER_BLOCK[0] : translations.ENTER_BLOCK[1]}
                component={Input}
                data-testid="business-location-block"
              />
            </Label>
          </div>
          <div className="w-full mb-0 md:mb-0">
            <Label title={<Text value={translations.CITY} />}>
              <Field
                name="cityId"
                onChange={e => {
                  handleChange(e);
                }}
                defaultValue={data?.cities?.[0].id || ''}
                placeholder={lang === 'en' ? translations.ENTER_CITY[0] : translations.ENTER_CITY[1]}
                component={Select}
                type="squared"
              >
                {data?.cities?.map(item => (
                  <option key={item.id} value={item.id}>
                    {translate(item)}
                  </option>
                ))}
              </Field>
            </Label>
          </div>
        </div>
        <div className="flex flex-col mt-2 md:flex-row justify-between items-start">
          <div className={cx('w-full mb-6 md:mb-0', branchId ? (lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4') : '')}>
            <Label title={<Text value={translations.DELIVERY_NOTE} />}>
              <Field
                type="text"
                name="deliveryNotes"
                placeholder={
                  lang === 'en' ? translations.ENTER_DELIVERY_NOTES[0] : translations.ENTER_DELIVERY_NOTES[1]
                }
                component={Input}
                data-testid="business-location-notes"
              />
            </Label>
          </div>
          {branchId && (
            <div className="w-full mb-0 md:mb-0">
              <Label title={<Text value={translations.AREA} />}>
                <Field
                  disabled
                  style={{ backgroundColor: '#f1f1f1' }}
                  type="text"
                  name="areaEn"
                  component={Input}
                  data-testid="business-location-area"
                />
              </Label>
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default BranchAddress;
