import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Typography, Spinner } from '@zydalabs/zac-react';

import { Modal } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';
import OrderDeliveryConfirmation from 'components/common/orders/OrderDeliveryConfirmation';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useFetchVerdDrivers } from 'service/hooks';
import StoreRiderInfo from './StoreRiderInfo';
import StoreCourierInfo from './StoreCourierInfo';
import useHandleAssignUnAssignDriverToOrder from './handleAssignDrivertoOrder';
import useHandleAssignUnAssignCourierToOrder from './handleAssignCourierToOrder';
import RestaurantNotHasRiders from './restaurantNotHasRiders';
import AssignDeliveryModal from './AssignDeliveryModal';
import useGetCourierInfoWithLogo from './useGetCourierInfoWithLogo';

export const OnFleetDeliveryActionButtons = ({ order, updatedCourierId, sendChangeStatus, open, close }) => {
  const { status: orderStatus, branchData } = order || {};
  const { translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const {
    selectedStore: { timeZone },
  } = useContext(userContext);

  const { data: activeRiders, isLoading: isActiveRidersLoading, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchData.id),
  });

  // variables
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;
  const orderDeliveryPhoneNumber = order?.deliveryCourier?.driverPhoneNumber;
  const quickAssignCourierId = order?.deliveryCourierId;
  const trackingLink = order?.deliveryCourier?.trackingLink;
  const deliveryOrderStatus = order?.deliveryStatus;
  const isScheduledOrder = order?.isScheduled;
  const firingTime = order?.firingTime;
  const referenceId = order?.deliveryCourier?.referenceId;
  const courierDriverName = order?.deliveryCourier?.driverName;
  const hasCourierDriverInfo = order?.deliveryCourier?.hasDriverInfo;
  const courierDriverAssigned = order?.deliveryCourier?.driverAssigned;
  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const isOrderCancelled = orderStatus === ORDER_STATUS.CANCELED;
  const isOrderDelivered = orderStatus === ORDER_STATUS.DELIVERED;

  const {
    restaurantCouriersWithLogos,
    isCouriersEstimationsLoading,
    courierEstimationsError,
  } = useGetCourierInfoWithLogo(order);

  const hasCourier = order?.deliveryCourier && updatedCourierId;
  const isDeliveryOrderStatusCancelledOrDeclined =
    deliveryOrderStatus === ORDER_RIDER_STATUS.CANCELED || deliveryOrderStatus === ORDER_RIDER_STATUS.DECLINED;
  const isOrderDeliveredOrCancelled = orderStatus === ORDER_STATUS.DELIVERED || orderStatus === ORDER_STATUS.CANCELED;

  const assignedDriver = riders?.find(driver => driver.phone === orderDeliveryPhoneNumber);
  const assignedCourier = restaurantCouriersWithLogos?.find(
    courier => Number(courier.courierId) === orderDeliveryCourierId,
  );
  const isOrderAssignedToCourierOrRider = !!assignedDriver?.id || !!assignedCourier?.courierId;
  const showDeliveryExpectedAt =
    !isDeliveryOrderStatusCancelledOrDeclined && !isOrderDeliveredOrCancelled && hasCourier;
  const isCourierNotDeclinedTheRequest = !!assignedCourier && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;

  const {
    handleAssignCourierToOrder,
    handleUnAssignCourierToOrder,
    isCourierInfoLoading,
  } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const openOrderDeliveryConfirmationPopUp = ({
    assignDriverToOrder,
    assignee,
    isAssigneeSupportCancellation,
    isUnAssignCourier,
    acceptOrder,
  }) =>
    open({
      title: <Text value={translations.CONFIRM_CANCELLATION} className="text-xl" />,
      body: (
        <OrderDeliveryConfirmation
          order={order}
          assignDriverToOrder={assignDriverToOrder}
          updatedCourierId={updatedCourierId}
          acceptOrder={acceptOrder}
          sendChangeStatus={sendChangeStatus}
          onCancel={close}
          isUnAssignCourier={isUnAssignCourier}
          isAssigneeSupportCancellation={isAssigneeSupportCancellation}
          assignee={assignee}
        />
      ),
      size: 'max-w-xl',
    });

  const {
    handleAssignDriverToOrder,
    handleUnassignDriverToOrder,
    isRiderInfoLoading,
  } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  if (isActiveRidersLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col">
        {!isRiderInfoLoading && !!assignedDriver && (
          <StoreRiderInfo
            deliveryOrderStatus={deliveryOrderStatus}
            orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
            assignedDriver={assignedDriver}
          />
        )}
        {!isCourierInfoLoading && !!assignedCourier && (
          <StoreCourierInfo
            order={order}
            deliveryOrderStatus={deliveryOrderStatus}
            referenceId={referenceId}
            hasDriverInfo={hasCourierDriverInfo}
            driverAssigned={courierDriverAssigned}
            orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
            driverName={courierDriverName}
            trackingLink={trackingLink}
            isScheduledOrder={isScheduledOrder}
            firingTime={firingTime}
            assignedCourier={assignedCourier}
          />
        )}
        <div className="gap-2 flex mb-4">
          {(riders?.length !== 0 || restaurantCouriersWithLogos?.length !== 0) && !isOrderCancelled && (
            <Modal customHeight="h-3/4">
              {({ open: openAssignDeliveryModal, close: closeAssignDeliveryModal, closeable }) => (
                <Button
                  isDisabled={isOrderDelivered}
                  variant="tertiary"
                  text={translate(
                    isOrderAssignedToCourierOrRider ? translations.CHANGE_DELIVERY : translations.ASSIGN_DELIVERY,
                  )}
                  rounded
                  isLoading={
                    isRiderInfoLoading ||
                    isCourierInfoLoading ||
                    (isCouriersEstimationsLoading && !courierEstimationsError)
                  }
                  isFluid={isOrderAssignedToCourierOrRider}
                  onClick={() =>
                    openAssignDeliveryModal({
                      body: (
                        <AssignDeliveryModal
                          close={closeAssignDeliveryModal}
                          handleAssignDriverToOrder={handleAssignDriverToOrder}
                          handleAssignCourierToOrder={handleAssignCourierToOrder}
                          openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
                          isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
                          assigned={isOrderAssignedToCourierOrRider}
                          restaurantCouriersWithLogos={restaurantCouriersWithLogos}
                          riders={riders}
                          quickAssignCourierId={quickAssignCourierId}
                          closeable={closeable}
                        />
                      ),
                    })
                  }
                />
              )}
            </Modal>
          )}
          {isOrderAssignedToCourierOrRider && (
            <Button
              variant="secondary"
              rounded
              isFluid
              isLoading={isRiderInfoLoading || isCourierInfoLoading}
              text={translate(translations.UNASSIGN)}
              onClick={() =>
                assignedDriver
                  ? handleUnassignDriverToOrder(assignedDriver)
                  : isCourierNotDeclinedTheRequest
                  ? openOrderDeliveryConfirmationPopUp({
                      assignDriverToOrder: handleUnAssignCourierToOrder,
                      assignee: assignedCourier,
                      isAssigneeSupportCancellation: assignedCourier?.isCourierSupportCancellation,
                      isUnAssignCourier: true,
                    })
                  : handleUnAssignCourierToOrder()
              }
            />
          )}
        </div>
        {riders?.length === 0 && !assignedCourier && (
          <RestaurantNotHasRiders
            mutateFetchVerdDrivers={mutateFetchVerdDrivers}
            sendChangeStatus={sendChangeStatus}
            handleAssignDriverToOrder={handleAssignDriverToOrder}
          />
        )}
      </div>
      {showDeliveryExpectedAt && (
        <div className="pt-3 border-t">
          <Typography variant="heading14">
            <Text value={translations.EXPECTED_DELIVERY_TIME} />
          </Typography>
          <span className="text-sm">
            {moment(order?.expectedAt)
              .tz(timeZone)
              .format('MMMM DD, YYYY - h:mm a')}
          </span>
        </div>
      )}
    </div>
  );
};

OnFleetDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string.isRequired,
    deliveryTime: PropTypes.number,
    branchData: PropTypes.shape({ id: PropTypes.string }),
    restaurantRiderBranchOrderData: PropTypes.shape({ restaurantRider: PropTypes.string, status: PropTypes.string }),
  }),
};
export default OnFleetDeliveryActionButtons;
